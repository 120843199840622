import { runInAction } from 'mobx';
import { RootStore } from '../stores/RootStore';
import { FedopsInteractions } from '@wix/wixstores-client-storefront-sdk/dist/es/src/enums/fedopsInteractions';
import { WishlistStatus } from '../../../app';

export const handleRemoveFromWishlist = (rootStore: RootStore) => {
  return rootStore.wixstoresApi.wishlist
    .removeProducts([rootStore.controllerParams.$widget.props.productId])
    .then(() =>
      runInAction(() => {
        rootStore.$state.isInWishlist = false;
        rootStore.controllerParams.$widget.fireEvent('wishlistClick', {
          status: WishlistStatus.Removed,
        });
      }),
    )
    .catch((e) => {
      rootStore.controllerParams.flowAPI.reportError(e);
      console.error(
        `failed to remove product id: ${rootStore.controllerParams.$widget.props.productId}, from wishlist`,
      );
    });
};

export const handleRemoveFromWishlistClick = (rootStore: RootStore) => {
  return rootStore.reportFedopsInteraction(
    FedopsInteractions.RemoveFromWishlist,
    () => handleRemoveFromWishlist(rootStore),
  );
};
