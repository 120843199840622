import model from './model';
import { WishlistButtonWidgetComponentIds as ComponentIds } from './config/constants';
import { RootStore } from './services/stores/RootStore';
import { handleAddToWishlistClick } from './services/handlers/handleAddToWishlistClick';
import { handleRemoveFromWishlistClick } from './services/handlers/handleRemoveFromWishlistClick';
import { initLocaleKeys } from './services/initLocaleKeys';
import { SPECS } from '../../specs';

export default model.createController((controllerParams) => {
  return {
    pageReady: async () => {
      const { $bindAll, $widget, flowAPI } = controllerParams;
      const t = initLocaleKeys(flowAPI.translations.i18n);
      const rootStore = new RootStore(controllerParams);

      if (
        flowAPI.experiments.enabled(SPECS.ProductPageBlocksWishlistSSR) &&
        flowAPI.environment.isSSR
      ) {
        return;
      }

      $widget.onPropsChanged(() => void rootStore.loadData());
      await rootStore.init();

      $bindAll({
        [ComponentIds.addButton]: {
          label: () => t.wishlist.button(),
          onClick: () => handleAddToWishlistClick(rootStore),
        },
        [ComponentIds.removeButton]: {
          label: () => t.wishlist.remove.button(),
          onClick: () => handleRemoveFromWishlistClick(rootStore),
        },
      });
    },
    updateWidgetViewState: (viewStateId) => {
      const { $w } = controllerParams;
      void $w(ComponentIds.multiStateBox).changeState(viewStateId);
    },
    exports: {},
  };
});
