
  import {createControllersWithDescriptors, initAppForPageWrapper} from '@wix/yoshi-flow-editor/runtime/esm/viewerScript/wrapper.js';
  
  
            
import wrapController0 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/blocks';

            import controller0 from '/home/builduser/work/cc2a08847946417c/packages/wixstores-client-wishlist-button/src/components/WishlistButton/viewer.controller.ts';
            import * as _controllerExport0 from '/home/builduser/work/cc2a08847946417c/packages/wixstores-client-wishlist-button/src/components/WishlistButton/model.ts';
            var controllerExport0 = _controllerExport0;
            

  
  var importedApp = {};


  

    import { initState, initBind } from '@wix/yoshi-flow-editor/runtime/esm/velocycleMobx';
    import { observable } from 'mobx';
    var velocycleMobx = { initState, initBind, observable };
    


  

    import { blocksControllerService } from '@wix/yoshi-flow-editor/runtime/esm/blocks/controllerService'
    

  
var createHttpClient = null;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
      DSN: 'https://dd84cdb4b7424f93a4f1cfbe0389bdab@o37417.ingest.sentry.io/4504841825026048',
      id: 'dd84cdb4b7424f93a4f1cfbe0389bdab',
      projectName: 'wishlist-button',
      teamName: 'wixstores',
      errorMonitor: true,
    };

  var experimentsConfig = {"scopes":[],"centralized":true};

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/cc2a08847946417c/packages/wixstores-client-wishlist-button/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hr","hu","id","it","ja","ko","lt","lv","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};

  var defaultTranslations = {"app.widget.welcome":"Welcome","app.settings.label":"Configure Widget","app.settings.tabs.main":"Main","app.settings.tabs.design":"Design","app.settings.defaults.greetingsPretext":"to","productPage.wishlistButton.design.removeFromWishlist.panelHeader":"Remove from Wishlist","wishlist.button":"Add to Wishlist","productPage.wishlistButton.design.removeFromWishlist.sectionName":"Remove from Wishlist","sr.wishlist.button":"Add to Wishlist","productPage.wishlistButton.design.panelHeader":"Wishlist Button Design","wishlist.remove.button":"Remove from Wishlist","productPage.wishlistButton.displayName":"Wishlist Button","productPage.wishlistButton.gfpp.design":"Design Wishlist Button","sr.wishlist.remove.button":"Remove from Wishlist","productPage.wishlistButton.design.addToWishist.panelHeader":"Add to Wishlist","productPage.wishlistButton.design.addToWishist.sectionName":"Add to Wishlist","productPage.wishlistButton.addToWishlist.state.label":"Add to Wishlist","productPage.wishlistButton.removeFromWishlist.state.label":"Remove from Wishlist"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/cc2a08847946417c/packages/wixstores-client-wishlist-button/target/generated/bi/createBILogger.ts';

  export const exports = importedApp.exports;

  export const initAppForPage = initAppForPageWrapper({
    initAppForPage: importedApp.initAppForPage,
    sentryConfig: sentryConfig,
    experimentsConfig: experimentsConfig,
    inEditor: false,
    biLogger: biLogger,
    multilingualDisabled,
    projectName: "wixstores-client-wishlist-button",
    biConfig: null,
    appName: "Wishlist Blocks",
    appDefinitionId: "a97c0203-062a-4dd5-97cf-a90f9800a13a",
    fedopsConfig: fedopsConfig,
    translationsConfig: translationsConfig,
    defaultTranslations: defaultTranslations,
    shouldUseEssentials: true,
    optionalDeps: {
      initI18n,
      createHttpClient,
      createExperiments,
    },
    localeDistPath: "assets/locales",
  });

  const _createControllers = createControllersWithDescriptors({
    initI18n,
    blocksControllerService,
    createHttpClient,
    createExperiments,
    velocycleMobx,
  }, [{ method: controller0,
          wrap: wrapController0,
          exports: controllerExport0,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"scopes":[],"centralized":true},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/cc2a08847946417c/packages/wixstores-client-wishlist-button/src/components/WishlistButton/viewer.controller.ts",
          appName: "Wishlist Blocks",
          appDefinitionId: "a97c0203-062a-4dd5-97cf-a90f9800a13a",
          projectName: "wixstores-client-wishlist-button",
          componentName: "WishlistButton",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "a97c0203-062a-4dd5-97cf-a90f9800a13a-e7umd" }],
    false);

    export const createControllers = _createControllers
