import { RootStore } from '../stores/RootStore';
import { runInAction } from 'mobx';
import { FedopsInteractions } from '@wix/wixstores-client-storefront-sdk/dist/es/src/enums/fedopsInteractions';
import { WishlistStatus } from '../../../app';

export async function handleAddToWishlist(rootStore: RootStore) {
  if (
    !rootStore.controllerParams.controllerConfig.wixCodeApi.user.currentUser
      .loggedIn
  ) {
    await rootStore.controllerParams.controllerConfig.wixCodeApi.user.promptLogin(
      {},
    );
  }
  return rootStore.wixstoresApi.wishlist
    .addProducts([rootStore.controllerParams.$widget.props.productId])
    .then(() =>
      runInAction(() => {
        rootStore.$state.isInWishlist = true;
        rootStore.controllerParams.$widget.fireEvent('wishlistClick', {
          status: WishlistStatus.Added,
        });
      }),
    )
    .catch((e) => {
      rootStore.controllerParams.flowAPI.reportError(e);
      console.error(
        ` failed to add product with productId: ${rootStore.controllerParams.$widget.props.productId} to wishlist`,
        e,
      );
    });
}

export const handleAddToWishlistClick = (rootStore: RootStore) => {
  return rootStore.reportFedopsInteraction(
    FedopsInteractions.AddToWishlist,
    () => handleAddToWishlist(rootStore),
  );
};
