import { RootStore } from './RootStore';
import { makeAutoObservable } from 'mobx';
import { RootStoreState } from '../../config/types';

const getIsInWishlist = (rootStore): boolean => {
  if (
    !rootStore.controllerParams.controllerConfig.wixCodeApi.user.currentUser
      .loggedIn ||
    !rootStore.controllerParams.$widget.props.productId
  ) {
    return false;
  }

  return rootStore.wixstoresApi.wishlist
    .getWishlist()
    .then((wishlist) => wishlist.items)
    .then((wishlistItems) =>
      wishlistItems
        .map((item) => item.id)
        .includes(rootStore.controllerParams.$widget.props.productId),
    );
};

export const getInitialData = async (rootStore: RootStore) => {
  const [isInWishlist] = await Promise.all([getIsInWishlist(rootStore)]);
  return { isInWishlist };
};

const setInitialState = (
  rootStore: RootStore,
  { isInWishlist }: { isInWishlist: boolean },
) => {
  return makeAutoObservable({
    isInWishlist,
  }) as RootStoreState;
};

export const createInitialState = async (rootStore: RootStore) => {
  return setInitialState(rootStore, await getInitialData(rootStore));
};
