import { IWixAPI } from '@wix/yoshi-flow-editor';
import { IViewerScriptExports } from '@wix/wixstores-client-storefront-sdk/dist/src/viewer-script/wixcode/createWixcodeExports';
import { ECOM_APP_DEF_ID } from '../../../app';
import { createInitialState, getInitialData } from './createInitialState';
import { RootStoreState, RootWidgetControllerParams } from '../../config/types';
import { autorun } from 'mobx';
import {
  WishlistButtonStates,
  WishlistButtonWidgetComponentIds as ComponentIds,
} from '../../config/constants';
import {
  createReportFedopsInteraction,
  ReportFedopsInteractionFn,
} from './createReportFedopsInteraction';

export class RootStore {
  public $state: RootStoreState;
  public wixstoresApi: IViewerScriptExports;
  public wixCodeApi: IWixAPI;
  public reportFedopsInteraction: ReportFedopsInteractionFn;

  constructor(public readonly controllerParams: RootWidgetControllerParams) {
    this.wixCodeApi = this.controllerParams.controllerConfig.wixCodeApi;
    this.reportFedopsInteraction = createReportFedopsInteraction(this);
  }

  private async initWidget() {
    this.$state = await createInitialState(this);

    autorun(() => {
      this.controllerParams.controllerConfig
        .$w(ComponentIds.multiStateBox)
        .changeState(
          this.$state.isInWishlist
            ? WishlistButtonStates.inWishlist
            : WishlistButtonStates.notInWishlist,
        );
    });
  }

  public async loadData() {
    const initialData = await getInitialData(this);
    this.$state.isInWishlist = initialData.isInWishlist;
  }

  public async init(): Promise<void> {
    this.wixstoresApi = await this.wixCodeApi.site.getPublicAPI(
      ECOM_APP_DEF_ID,
    );
    await this.initWidget();
    this.wixCodeApi.location.onChange(() => void this.initWidget());
  }
}
