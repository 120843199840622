import { RootStore } from './RootStore';

export type ReportFedopsInteractionFn = (
  interaction: string,
  fn: () => unknown,
) => Promise<void>;

export type ReportFedopsFn = (
  rootStore: RootStore,
) => ReportFedopsInteractionFn;

export const createReportFedopsInteraction: ReportFedopsFn =
  (rootStore) =>
  async (interaction, fn): Promise<void> => {
    rootStore.controllerParams.flowAPI.fedops.interactionStarted(interaction);
    await fn();
    rootStore.controllerParams.flowAPI.fedops.interactionEnded(interaction);
  };
